import Vue from 'vue'
import * as firebase from 'firebase/app';
import 'firebase/auth';
import "@firebase/firestore";

// import store from './store'

const firebaseConfig = {
    apiKey: "AIzaSyCEP-zMdLVPCePa2jZaDfcsfq_323gvP1o",
    authDomain: "bbbmc-c4f53.firebaseapp.com",
    databaseURL: "https://bbbmc-c4f53-default-rtdb.firebaseio.com",
    projectId: "bbbmc-c4f53",
    storageBucket: "bbbmc-c4f53.appspot.com",
    messagingSenderId: "1024576892323",
    appId: "1:1024576892323:web:7c047b51f83c4e469cfe16",
    measurementId: "G-8LGK903PSE"
  };

firebase.initializeApp(firebaseConfig);

export default {
	auth: firebase.auth(),
	db: firebase.firestore(),

	async getPrivilegeGameConfig()
	{
		var config = {}
		await firebase.firestore().collection("privilegeGameSettings")
			.limit(1)
			.get()
			.then(async (snapshot) => {
				// config = await snapshot.docs.map(doc => doc.id)
				// config = await snapshot.docs[0].data()
				config  = await snapshot.docs[0].data()


				// console.log("-X--------")
				// // console.log(config)
				// console.log(snapshot.docs[0].data())
				// console.log("-X--------")

				// cb()
				// {
					
				// }
			})

			return config
	},
	async getShowStage()
	{
		Vue.$log.debug("firebase.js | getShowStage()")

		var config = []
		await firebase.firestore().collection("showState")
		.orderBy("dateCreated", "desc")
		.limit(1)
		.get()
		.then(async (snapshot) =>
		{
			config = await snapshot.docs.map(doc => doc.id)
		})
		.catch(function(error)
		{
			var toastOptions = {
				duration: 1000,
				type: "error",
				theme: "bubble"
			}
			Vue.toasted.show('Error: ' + error.code, toastOptions);

			Vue.$log.fatal("firebase.js | getShowStage()", error.code, error.message, error.email, error.credential)
		})

		Vue.$log.debug("firebase.js | getShowStage() | showID: ", config[0], " | showConfig: ", config)

		return config
	},
	async getShowConfig(showID)
	{
		// var config = {}

		await firebase.firestore().collection("showState").doc(showID).get()
		.then((snapshot) => {
			if (! snapshot)
			{
				return
			}

			return snapshot
			// config = snapshot
		})

		// return config
	},

	async setUsername(showID, username)
	{
		Vue.$log.debug("firebase.js | setUsername() | showID: ", showID, " username: ", username)
		var washingtonRef = this.db.collection("showState").doc(showID)
		await washingtonRef.update({
			people: firebase.firestore.FieldValue.arrayUnion(username)
			// regions: firebase.firestore.FieldValue.arrayUnion("greater_virginia")
		})

		// await firebase.firestore().collection("timeline").add(timelineData)

	}
}
