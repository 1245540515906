import Vue from 'vue'
import App from './App.vue'
// import VueMeta from 'vue-meta'
import './registerServiceWorker'
import router from './router'
import VueHead from 'vue-head'
import store from './store'
import VueLogger from 'vuejs-logger';
import Toasted from 'vue-toasted';
import VueCookies from 'vue-cookies';
import VueThermometer from 'vuejs-thermometer'
// import VuePhaser from 'vue-phaser'
import { defineCustomElements as defineIonPhaser } from '@ion-phaser/core/loader';

import 'vue-next-select/dist/index.min.css'

import {
	BootstrapVue,
	LayoutPlugin,
	BootstrapVueIcons,
	IconsPlugin,
	CardPlugin,
	ImagePlugin,
	AlertPlugin
} from 'bootstrap-vue'


// Vue.use(VueMeta)

// Import Bootstrap an BootstrapVue CSS files (order is important)
import './app.scss'
// import 'bootstrap/scss/bootstrap.scss'
// import 'bootstrap-vue/src/index.scss'


// import "@/assets/sass/black-dashboard.scss";

Vue.use(VueHead)


// Make BootstrapVue available throughout your project
Vue.use(LayoutPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(CardPlugin)
Vue.use(ImagePlugin)
Vue.use(AlertPlugin)
Vue.use(VueThermometer)
// Vue.use(VuePhaser)


Vue.config.productionTip = false;
Vue.config.ignoredElements = [/ion-\w*/];
 
defineIonPhaser(window);


// TODO!  Don't use this kinda flags
const isProduction = process.env.NODE_ENV === 'production';

const options = {
	isEnabled: true,
	logLevel : isProduction ? 'error' : 'debug',
	stringifyArguments : false,
	showLogLevel : true,
	showMethodName : true,
	separator: '|',
	showConsoleColors: true
};

Vue.use(VueLogger, options);
Vue.use(Toasted)
Vue.use(VueCookies);


Vue.config.productionTip = false

// Vue.use(VueMeta)

// Vue.use(VueMeta, {
// 	refreshOnceOnNavigation: true
//   })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

