<template>
	<div class="home">
		<Start v-if="this.stateType == 'START'" />
		<GiveupPrivilege v-if="this.stateType == 'GIVEUP_PRIVILEGE'" />
		<AnimatedLogo v-if="this.stateType == 'ANIMATED_LOGO'" />
		<Question v-if="this.stateType == 'QUESTION'"
			v-bind:questionID="this.questionID"
			v-bind:questionLabel="this.questionLabel"
			v-bind:multipleChoice="this.multipleChoice"
		/>
		<MultiQuestion v-if="this.stateType == 'MULTIQUESTION'"
			v-bind:questionID="this.questionID"
			v-bind:questionLabel="this.questionLabel"
			v-bind:multipleChoice="this.multipleChoice"
		/>
		<PrivilegeGame v-if="this.stateType == 'PRIVILEGE_GAME'" />
		<End v-if="this.stateType == 'END'" />
		<Game v-if="this.stateType == 'GAME'" />
	</div>
</template>

<script>
import Vue from 'vue'
// @ is an alias to /src
import GiveupPrivilege from '@/components/states/GiveupPrivilege.vue'
import AnimatedLogo from '@/components/states/AnimatedLogo.vue'
import PrivilegeGame from '@/components/states/PrivilegeGame.vue'
import Start from '@/components/states/Start.vue'
import End from '../components/states/End.vue'
import Question from '@/components/states/Question.vue'
import MultiQuestion from '@/components/states/MultiQuestion.vue'
import Game from '@/components/states/Game.vue'

import Firebase from '../firebase.js';

export default {
	name: 'Home',
	components:
	{
		Start,
		GiveupPrivilege,
		AnimatedLogo,
		MultiQuestion,
		Question,
		PrivilegeGame,
		End,
		Game
	},
	data()
	{
		return {
			username: '',
			stateType: 'SIGNIN',
			stateID: '',
			state: {},
			questionID: '',
			questionLabel: '',
			multipleChoice: []
		}
	},
	async mounted()
	{
		Vue.$log.debug("views/Home.vue")

		if (! this.isLoggedIn())
		{
			console.log("logged out")
			this.$router.push("/login")
			return
		}
		else
		{
			console.log("logged in")
		}

		Firebase.db.collection("showState")
		.onSnapshot((snapshot) =>
		{
			this.collectionData = []
			this.people = []
			snapshot.forEach((doc) =>
			{
				this.stateID      = doc.data()['stateID']

				if (this.stateID)
				{
					Firebase.db.collection("showStates")
					.doc(this.stateID)
					.get()
					.then((doc) => {
						this.stateType      = doc.data()['stateType']
						this.questionID     = doc.id
						this.questionLabel  = doc.data()['questionLabel']
						this.multipleChoice = doc.data()['multipleChoice']
					})
				}
			})
			// TEMP: ISUiMtwObAKw7T4ywR6E
			this.stateID = "ISUiMtwObAKw7T4ywR6E"
		})
	},
	methods:
	{
		isLoggedIn()
		{
			return this.$cookies.get("username") ? true : false
		}
	}
}
</script>

<style scoped lang="scss">
.nextButton
{
	padding: 5px;
}

</style>
