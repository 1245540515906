<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<b-card>
					<h1>Are you ready to commit to releasing your white privilege this evening ?</h1>
						<b-alert :show="showError ? true : false" variant="danger">
							You selected the wrong answer, so we've selected the right one for you
						</b-alert>
					<ol class="multipleChoice">
						<li>
							<b-button pill size="lg" :variant="isSelected ? 'primary' : 'secondary'" v-on:click="yesAction()">Yes</b-button>
						</li>
						<li>
							<b-button pill size="lg" variant="secondary" v-on:click="noAction()">No</b-button>
						</li>
					</ol>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "GiveupPrivilege",
	data()
	{
		return {
			isSelected: false,
			showError: false
		}
	},
	methods:
	{
		yesAction: function()
		{
			this.isSelected = true
		},
		noAction: function()
		{
			this.showError  = true
			this.isSelected = true
		}
	}
}
</script>

<style lang="css">
.multipleChoice
{

}

.multipleChoice li
{
	list-style-type: none;
}

.multipleChoice button
{
	width: 200px;
}

.multipleChoice li
{
	padding: 10px;
}

.selected
{
	background-color: green;
	color: #ffffff;
}
</style>

