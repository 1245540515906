<template>
	<div>
		<b-row>
			<b-col><img src="@/assets/AnimatedLogo.gif" /></b-col>
		</b-row>
        <b-row>
            <b-col><h1>Thanks for attending!</h1></b-col>
        </b-row>
	</div>
</template>

<script>
export default {
	name: 'End'
}
</script>
