
<template>
	<div class='hello'>
		<div @click="initializeGame" class="flex">
			<a href="#1" class="btn">Initialize</a>
		</div>
		<ion-phaser v-bind:game.prop='game' v-bind:initialize.prop='initialize' />
	</div>
</template>

<style>
#plano {
	position: absolute;
	top: 0;
	left: 0;
	width: 1280px;
	height: 640px;
	margin: 0 auto;
}

#plano canvas {
	display: block;
	margin: 0 auto;
}
</style>

<script>
/* eslint-disable no-unused-vars */
// import 'pixi'
// import 'p2'




import Phaser from "phaser";

export default {
	data() {
		return {
			initialize: false,
			game: {
				width: "100%",
				height: "100%",
				type: Phaser.AUTO,
				scene: {
					init() {
						this.cameras.main.setBackgroundColor("#24252A");
					},
					create() {
						this.helloWorld = this.add.text(
							this.cameras.main.centerX,
							this.cameras.main.centerY,
							"Hello World",
							{ font: "40px Arial", fill: "#ffffff" }
						);
						this.helloWorld.setOrigin(0.5);
					},
					update() {
						this.helloWorld.angle += 1;
					}
				}
			}
		};
	},
	methods: {
		initializeGame() {
			this.initialize = true;
		}
	}
};





/*
import Phaser from 'phaser'

export default {
	name: 'game',
	props: {
		width: Number,
		height: Number
	},
	mounted() {
		let self = this
		if (this.game == null) {
			this.game = new Phaser.Game(this.width, this.height, Phaser.AUTO, this.$el, {
				preload: function preload() {
					console.log("preload")
					self.preload(this)
				},
				create: function create() {
					self.create(this)
				},
				update: function update() {
					self.update(this)
				}
			})
		}
	},
	methods: {
		preload() {
		},
		create(phaser) {
			console.log(":create")
		},
		update(phaser) {
		}
	},
	data() {
		return {
			game: null
		}
	}
}

*/
/*
// game options, passed directly to `new Phaser.Game`
const options = {
	physics: {
		default: 'arcade',
		arcade: {
			debug: true,
			gravity: {
				y: 300,
			},
		},
	},
}

// preload queue - assets for scene to load
const preloadQueue = ['player.png', 'block.png']

export default {
	data(){
		return {
			options,
			preloadQueue
		}
	},
	create()
	{
		console.log("create()")
	},
	async mounted()
	{
	// 	this.add.text(100, 100, "PlayScene", {
	// 		font: "24px Courier",
	// 		fill: "#ffffff",
	// 	});
		
	// 	console.log("mounted")
	// 	let btt = this.add.text(0, 0, 'Best time!', {
	// 	fontSize: '32px',
	// 		fill: '#FF0000'
	// 	}).setOrigin(0.5);

	// let container = this.add.container(400, 100, [btt]);

	// this.physics.world.enableBody(container);

	// container.body.setVelocity(100, 200);
	// container.body.setBounce(1, 1);
	// container.body.setCollideWorldBounds(true);
		
	},
	methods:
	{
		test()
		{
			console.log('test')
			console.log(this.$game)
			// this.add.text(100, 100, "PlayScene", {
			// 	font: "24px Courier",
			// 	fill: "#ffffff",
			// });
			// let container = this.add.container(400, 100, []);
			// console.log(container)
		}
	}
}
*/
</script>
