<template>
	<div>x
		<b-row>
			<b-col><img src="@/assets/AnimatedLogo.gif" /></b-col>
		</b-row>
		<b-row>
			<b-col><h1>Show starting soon</h1></b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	name: 'Start'
}
</script>
