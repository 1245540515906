<template>
	<div class="home">
		<Game2 />
	</div>
</template>

<script>
/* eslint-disable no-unused-vars */

import Vue from 'vue'
// @ is an alias to /src
import Game from '@/components/states/Game.vue'
import Game2 from '@/components/states/Game2.vue'

export default {
	name: 'GameHome',
	components:
	{
		// Game,
		Game2
	},
	data()
	{
		return {
			username: '',
			stateType: 'SIGNIN',
			stateID: '',
			state: {},
			questionID: '',
			questionLabel: '',
			multipleChoice: []
		}
	},
	async mounted()
	{
		Vue.$log.debug("views/Game.vue")

		// Firebase.db.collection("showState")
		// .onSnapshot((snapshot) =>
		// {
			// this.collectionData = []
			// this.people = []
			
            /*
            snapshot.forEach((doc) =>
			{
				this.stateID      = doc.data()['stateID']

				if (this.stateID)
				{
					Firebase.db.collection("showStates")
					.doc(this.stateID)
					.get()
					.then((doc) => {
						this.stateType      = doc.data()['stateType']
						this.questionID     = doc.id
						this.questionLabel  = doc.data()['questionLabel']
						this.multipleChoice = doc.data()['multipleChoice']
					})
				}
			})
            */
			// TEMP: ISUiMtwObAKw7T4ywR6E
			this.stateID = "ISUiMtwObAKw7T4ywR6E"
		// })
	},
	methods:
	{
		isLoggedIn()
		{
			return this.$cookies.get("username") ? true : false
		}
	}
}
</script>

<style scoped lang="scss">
.nextButton
{
	padding: 5px;
}

</style>
