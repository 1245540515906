<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<b-card>
					<h2 class="questionLabel">{{ this.questionLabel }}</h2>
					<ol v-for="(item, index) in multipleChoice" :key="item.id" class="multipleChoice">
						<li>
							<div class="letter row">
								<div class="col-1">
									{{ letters[index] }}
								</div>
								<div class="col-10">
									<b-button
										size="lg"
										:variant="isChecked(index) == true ? 'primary' : 'secondary'"
										v-on:click="clickAction(index)"
									>{{ item }}</b-button>
								</div>
							</div>
						</li>
					</ol>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
import Firebase from '@/firebase.js';
import * as firebase from 'firebase/app';

export default {
	name: 'MultiQuestion',
	data()
	{
		return {
			selectedIDs: Array(),
			letters: ["A", "B", "C", "D", "E", "F"]
		}
	},
	mounted()
	{
		console.log(this.state)
	},
	props:
	{
		questionID: String,
		questionLabel: String,
		state: Object,
		multipleChoice: Array
	},
	methods:
	{
		isChecked(index)
		{
			if (this.selectedIDs.indexOf(index) >= 0)
			{
				return true
			}
			else
			{
				return false
			}
		},
		clickAction: function(answer)
		{
			var showID = this.$cookies.get("showID")

			const data = {
				multipleChoice: {}
			}
			if (this.isChecked(answer))
			{
				this.selectedIDs.splice(this.selectedIDs.indexOf(answer), 1)

				data['multipleChoice'][answer] = firebase.firestore.FieldValue.arrayRemove(this.$cookies.get("username"))
			}
			else
			{
				this.selectedIDs.push(answer)

				data['multipleChoice'][answer] = firebase.firestore.FieldValue.arrayUnion(this.$cookies.get("username"))
			}

			Firebase.db.collection("answers").doc(showID + "_" + this.questionID).set(data, { merge: true })
				.catch((error) => {
					console.error("Error adding document: ", error);
				})
				// console.log(this.selectedIDs)
		},
	}
}
</script>

<style lang="css">
.letter
{
	font-size: 2rem;
	/* margin: 10px; */
	/* padding: 20px; */
}

.questionLabel
{
	padding-bottom: 10px;
}

.multipleChoice
{

}

ol.multipleChoice
{
	padding: 0px;
}

.multipleChoice li
{
	list-style-type: none;
}

.multipleChoice button
{
	width: 90%;
}

.multipleChoice li
{
	/* padding: 10px; */
}

.selected
{
	background-color: green;
	color: #ffffff;
}
</style>
