<template>
	<div id="app">
		<b-container>
			<div id="nav">
				
			</div>
			<!-- <div id="nav">
				<router-link to="/">Audience member</router-link> |
				<router-link to="/admin">Admin</router-link>
			</div> -->
			<router-view/>
		</b-container>
	</div>
</template>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

</style>

<script>
export default
{
	'name': 'App',
	head:
	{
		title: function()
		{
			return {
				inner: "Black Benatar's Black Magic Cabaret"
			}
		}
	},

	// metaInfo:
	// {
	// 	title: "Black Benatar's Black Magic Cabaret",
	//   meta:
	//   [
	//     { charset: 'utf-8' },
	//     {
	//       name: "viewport", content: "width=device-width, initial-scale=1, shrink-to-fit=no"
	//     }
	//   ]
	// }
}
</script>