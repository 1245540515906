<template>
	<div id="gameScreen">
		<h1 v-if="this.gameover" class="gameover">You weren't able to get rid of all your privilege, but keep trying - it's a lifelong effort!</h1>
		<div v-if="!this.gameover">
			<h1 id="titleScore">Privilege score: {{ this.currentValue }}</h1>
			<div class="mx-auto thermometerContainer d-flex justify-content-center">
				<div id="posScoreContainer"></div>
				<vue-thermometer
					:value="this.currentValue"
					:min="0"
					:max="this.maxValue"
					:options="this.options"
				/>
			</div>
			<div id="negScoreContainer">
				<b-button pill size="lg" class="gameButton" style="padding: 50px" v-on:click.stop.prevent="click">Click me quick!</b-button>
			</div>
		</div>
	</div>
</template>

<style>
div.greenScore {
	position: relative;
	top: 500px;
	left: 55%;
	animation: mymove 2s;
	size: 8em;
	font-size: 2em;
}

div.redScore {
	color: red;
	left: 55%;
	position: relative;
	top: 100px;
	animation: mymove 2s;
	size: 8em;
	font-size: 2em;
}

.test
{
	/* border: white solid thin; */
}

.greenScore
{
	color: green;
}

@keyframes mymove {
	0% {
		opacity: 0;
	}

	25% {
		transform: translateY(4rem);
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/* 
.gameButton {
  padding: 10px 25px;
  background: red;
  color: white;
  margin: 50px;
  border: none;
  position: relative;
  outline: none;
}
.gameButton:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: black;
  z-index: -1;
}
.gameButton:focus:before {
  transform: scale(2);
  transition: all 0.4s linear;
  opacity: 0;
} */


</style>


<script>
/* eslint-disable no-unused-vars */

import firebase from '@/firebase.js'
import $ from "jquery";

export default {
	'name': 'PrivilegeGame',
	data()
	{
		return {
			config: {},
			// decrementAmount: -37,
			// incrementAmount: 9,
			currentValue: 0,
			// Used to calculate pos score value
			lastScoreForPos: 0,
			maxValue: 20000,
			// interval: 30,
			timer: null,
			pTimer: null,
			timerRunning: true,
			pTimerRunning: true,
			gameover: false,
			// Used to remove dynamically created score text that floats up
			pScoreIdx: 0,
			nScoreIdx: 0,
			options:
			{
				layout:
				{
					height: 360,
					width: 150
				},
				text:
				{
					textEnabled: false
				},
				thermo:
				{
					ticksEnabled: false,
					tickWidth: 0,
					color: '#FFFFFF',
					backgroundColor: '#000000',
					frameColor: 'grey',
				}
			}
		}
	},
	head:
	{
		// <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
		meta:
		[
			{
				name: "viewport",
				// content: "width=device-width, initial-scale=1, shrink-to-fit=no"
				content: "width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0"
			}
		]
	},
	async mounted()
	{
		this.config = await firebase.getPrivilegeGameConfig();

		this.next()
		this.pNext()

		console.log("decrementAmount: " + this.config["decrementAmount"])
		console.log("incrementAmount: " + this.config["incrementAmount"])
		console.log("interval: " + this.config["interval"])
		console.log("maxValue: " + this.config["maxValue"])
		console.log("positiveScoreInterval: " + this.config["positiveScoreInterval"])
	},
	beforeDestroy()
	{
		window.removeEventListener('focus', this.onFocus)
		clearTimeout(this.timer)
		clearTimeout(this.pTimer)
	},
	methods:
	{
		next()
		{
			if (this.timerRunning)
			{
				this.timer = setTimeout(this.step.bind(this), parseInt(this.config["interval"]))
			}
		},
		pNext()
		{
			// console.log("pNext")
			if (this.pTimerRunning)
			{
				this.pTimer = setTimeout(this.pStep.bind(this), parseInt(this.config["positiveScoreInterval"]))
			}
		},
		step()
		{
			// Why in the ever loving fuck isn't this working ?!
			this.updateValue(parseInt(this.config["incrementAmount"]))
			// this.updateValue(this.incrementAmount)
			this.next()
		},
		pStep()
		{
			this.pNext()

			console.log("pStep")

			let _scoreIdx = this.pScoreIdx++

			let s = parseInt(this.config["positiveScoreInterval"] / this.config["incrementAmount"])

			s = (this.lastScoreForPos - this.currentValue) * -1
			this.lastScoreForPos = this.currentValue

			// console.log(this.lastScoreForPos)
			// console.log(this.currentValue)
			// console.log(s)

			$('#posScoreContainer').append("<div style=\"color: red\" class=\"center test redScore score" + _scoreIdx + "\">+" + s + "</div>")

			$(".score" + _scoreIdx).fadeOut(2000, function () {
				$(".score" + _scoreIdx).remove()
			})
		},
		click: function()
		{
			this.updateValue(this.config["decrementAmount"])

			let _scoreIdx = this.nScoreIdx++

			$(".gameButton").fadeOut(10).fadeIn(10).fadeOut(10).fadeIn(10)
			// $(".vue-thermometer").fadeOut(10).fadeIn(10).fadeOut(10).fadeIn(10)

			$('#negScoreContainer').append("<div style=\"color: green\" class=\"test greenScore score" + _scoreIdx + "\">" + this.config["decrementAmount"] + "</div>")

			$(".score" + _scoreIdx).fadeOut(2000, function () {
				// console.log(_scoreIdx)
				$(".score" + _scoreIdx).remove()
			})
		},
		updateValue(newValue)
		{
			// The * makes it work, it's not perfect but it works
			// Otherwise clicking the button a number of times will cause the bar to go over
			if (this.currentValue >= (parseInt(this.config["maxValue"]) - parseInt(this.config["incrementAmount"]) * 16))
			{
				clearTimeout(this.timer)
				this.endGame()
				return
			}

			this.currentValue += newValue
		},
		endGame()
		{
			this.currentValue = parseInt(this.config["maxValue"])
			this.gameover = true

			this.timerRunning = false
		}
	}
}

</script>

<style lang="scss">
.gameButton
{
	margin-top: 20px;
	padding: 200px;
}

.thermometerContainer
{
	padding: 5px 0px 10px 60px;
}

.gameover
{
	// color: red;
}

</style>

