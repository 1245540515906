<template>
	<div class="container">
		<b-row>
			<b-col><img src="@/assets/AnimatedLogo.gif" /></b-col>
		</b-row>

		<b-row>
			<b-col>
				<input id="name" name="username" v-model="username" placeholder="Please enter your name" style="width: 300px; margin-bottom: 30px" />
				<span class="nextButton">
					<button v-on:click="nextButton">Next</button>
				</span>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import Vue from 'vue'
import Firebase from '../firebase.js';

export default {
	name: 'Home',
	data()
	{
		return {
			username: '',
			showForm: false,
			showID: ''
		}
	},
	async mounted()
	{
		this.username = this.$cookies.get("username")
		if (! this.username)
		{
			this.showForm = true
		}
	},
	methods:
	{
		async nextButton()
		{
			Vue.$log.debug("views/Home.vue | nextButton()")
			const showState = await Firebase.getShowStage()
			const showID    = showState[0]
			this.$cookies.set("showID", showID)
			this.$cookies.set("username", this.username)

			Firebase.setUsername(showID, this.username)

			Vue.$log.debug("views/Home.vue | showState | ", showState)
			this.$router.push("/")
		}
	}
}
</script>

<style scoped lang="scss">
.nextButton
{
	padding: 5px;
}
</style>
