<template>
    <div id="gameScreen">

        <b-row>
            <b-col>
                <b-button
                    size="lg"
                    v-on:click="addScore(456)"
                >Add score</b-button>

            </b-col>
        </b-row>
    </div>
</template>

<style>
div.test {
    width: 100px;
    height: 100px;
    color: white;
    position :absolute;
    -webkit-animation: mymove 2s;
    animation: mymove 2s;
    size: 8em;
    font-size: 2em;
}
@-webkit-keyframes mymove {
   0%{opacity:0;}
   25%{transform:translateY(2rem);}
   50%{opacity:1;}
   100%{opacity:0;}
}
</style>

<script>
/* eslint-disable no-unused-vars */
import $ from "jquery";


export default {
    name: 'Game2',
    data() {
        return {
            totalScore: 0,
            scoreIdx: 0
        }
    },
    methods:
    {
        addScore(scoreValue)
        {
            let _scoreIdx = this.scoreIdx++

            $('#gameScreen').append("<div class=\"test score" + _scoreIdx + "\">123</div>")

            
            $(".score" + _scoreIdx).fadeOut(2000, function ()
            {
                console.log("done")
                $(".score" + _scoreIdx).remove()
            })
            
        }
    }
}
</script>
