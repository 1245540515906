<template>
	<div>
		<b-row>
			<b-col><img src="@/assets/AnimatedLogo.gif" /></b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	name: 'LogoBetweenStates'
}
</script>
